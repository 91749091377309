import { AsyncPipe, NgClass, NgIf, NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { getAuthenticatedStatus } from '@app/reducers/auth/auth.service';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';

import { SubscriptionPlanDirective } from '../../../../../../src/app/shared/subscription-plan/subscription-plan.directive';
import { EmployeeModel } from '../../../../reducers/orm/employee/employee.model';
import { IconComponent } from '../../icon.component';
import { PermissionDirective } from '../../permission.directive';
import { getMyOpenRequestsCounterWithoutRejections, getSupervisorOpenRequestsCounter } from '../sb-header.selectors';
import { AppState } from './../../../../reducers/index';
import { PlanType } from './../../../+reports/shared/subscriptions/subscription.model';
import { SBHeaderService } from './../sb-header.service';
import { SBProfileClockInItemComponent } from './items/sb-profile-clock-in-item';
import { SBProfileClockOutItemComponent } from './items/sb-profile-clock-out-item';
import { SBProfileClockSwitchItemComponent } from './items/sb-profile-clock-switch-item';
import { SBProfileItemComponent } from './items/sb-profile-item';

@Component({
  selector: 'sb-profile-dropdown',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    NgStyle,
    NgClass,
    IconComponent,
    TranslateModule,
    SBProfileItemComponent,
    PermissionDirective,
    SubscriptionPlanDirective,
    RouterModule,
    SBProfileClockInItemComponent,
    SBProfileClockOutItemComponent,
    SBProfileClockSwitchItemComponent,
    AsyncPipe,
  ],
  templateUrl: 'sb-profile-dropdown.html',
})
export class SBProfileDropdownComponent {
  @Input()
  public open = false;
  @Input()
  public employee: EmployeeModel;
  @Input()
  public size: number;
  @Input()
  public notificationCount: number;

  public planType = PlanType;

  public supervisorOpenRequests$ = this.store.select(getSupervisorOpenRequestsCounter);
  public myOpenRequests$ = this.store.select(getMyOpenRequestsCounterWithoutRejections);
  public authStatus$ = this.store.select(getAuthenticatedStatus);

  public constructor(
    private store: Store<AppState>,
    public headerService: SBHeaderService,
  ) {}
}

import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { of, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { fieldChangeWithStart } from '../../../../forms/form.helper';
import { AppState } from '../../../../reducers';
import { ReportFavoriteModel } from '../../../../reducers/orm/report-favorites/report-favorite.model';
import { getReportFavorites } from '../../../../reducers/orm/report-favorites/report-favorite.selector';
import { ReportFavoriteService } from '../../../../reducers/orm/report-favorites/report-favorite.service';
import { reportOptions, ReportType } from '../../../../reducers/orm/reports/report.model';
import { ReportService } from '../../../../reducers/orm/reports/report.service';
import { RouteModalComponent } from '../../../route-modal.component';

@Component({
  selector: 'modal-report-favorite-list-modal',
  templateUrl: './report-favorite-list-modal.component.html',
  styleUrls: ['report-favorite-list-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportFavoriteListModalComponent implements OnInit, OnDestroy {
  @ViewChild(RouteModalComponent, { static: true })
  public modal: RouteModalComponent;

  public positions = [
    new ConnectionPositionPair({ originX: 'start', originY: 'bottom' }, { overlayX: 'start', overlayY: 'top' }),
  ];

  public isLoading = true;
  public reportOptionSettings = reportOptions;

  public reportTypes$ = of(Object.values(ReportType)).pipe(
    map((reportTypes) =>
      reportTypes.map((reportType) => ({
        value: reportType,
        text: this.translate.instant(reportOptions[reportType].label),
      })),
    ),
  );

  public reportTypeCtrl = new UntypedFormControl(Object.values(ReportType));
  public reportFavorites$ = fieldChangeWithStart(this.reportTypeCtrl).pipe(
    switchMap((reports) => this.store.select(getReportFavorites(reports))),
  );

  private dataSubs = new Subscription();

  public constructor(
    private reportFavoriteService: ReportFavoriteService,
    private store: Store<AppState>,
    private reportService: ReportService,
    private translate: TranslateService,
  ) {}

  public close() {
    this.modal.close();
  }

  public loadFavorite(favorite: ReportFavoriteModel) {
    this.reportService.fetchReports.next(true);

    this.reportService.selectReport({ type: 'report', report: favorite.type, parameters: favorite.parameters }, true);
  }

  public delete(id: string) {
    this.dataSubs.add(this.reportFavoriteService.deleteFavorite(id).subscribe());
  }

  public ngOnInit() {
    this.dataSubs.add(
      this.reportFavoriteService.getFavorites().subscribe({
        complete: () => {
          this.isLoading = false;
        },
      }),
    );
  }

  public ngOnDestroy() {
    this.dataSubs.unsubscribe();
  }
}
